/*=============================
  Theme Name: ----------------.
  Author: Mrh Ripon
  Support: mrhripon98@gmail.com
  Description: --------------.
  Version: 1.0
==============================*/

/* Base Css Start  */
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap");
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
@import "variables.css";

html {
    font-size: 62.5%;
    height: 100%;
}

body {
    font-family: "Nunito Sans";
    font-weight: normal;
    font-style: normal;
    background-color: #eef1f4;
    height: calc(100% - 60px);
}

#root {
    height: 100%;
}

.fix {
    overflow: hidden;
}

p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 24px;
    color: #333555;
    margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito Sans";
    font-weight: 700;
    color: #333555;
    margin-top: 0px;
}

h2 {
    font-size: 3.6rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2.2rem;
}

h5 {
    font-size: 1.8rem;
}

h6 {
    font-size: 1.6rem;
}

a,
span {
    font-size: 1.6rem;
}

.img {
    max-width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

span,
a {
    display: inline-block;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.cursor-pointer {
    cursor: pointer;
}

div[data-relative="yes"] {
    position: relative;
}

.bg-primary {
    background-color: var(--c-bg-primary) !important;
}

.btn-outline-primary {
    padding: 10px 15px;
    max-width: 200px;
    border-color: var(--c-bg-primary) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    background-color: var(--c-bg-primary) !important;
    border-color: var(--c-bg-primary) !important;
    box-shadow: none !important;
    color: white;
}

.text-small {
    font-size: 1.3rem;
    color: #000000;
}

.margin-right-leftbar {
    margin-right: 80px;
}

.bg-light-red {
    background-color: var(--c-bg-wrong-answer);
}

.bg-light-purple {
    background-color: var(--c-bg-light-purple);
}

.bg-light-purple-1 {
    background-color: var(--c-bg-light-purple-1);
}

.bg-light-blue {
    background-color: var(--c-bg-light-blue);
}

.bg-dark-purple {
    background-color: var(--c-bg-dark-purple);
}

.bg-dark-blue {
    background-color: var(--c-bg-dark-blue);
}

.bg-light-orange {
    background-color: var(--c-bg-light-orange);
}

.bg-dark-orange {
    background-color: var(--c-bg-dark-orange);
}

.rounded-top {
    border-top-left-radius: 1.25rem !important;
    border-top-right-radius: 1.25rem !important;
}

.rounded-bottom {
    border-bottom-left-radius: 1.25rem !important;
    border-bottom-right-radius: 1.25rem !important;
}

.fit-content {
    max-width: fit-content;
}

.border-primary {
    border: 1px solid var(--c-bg-primary) !important;
}

.text-primary {
    color: var(--c-bg-primary) !important;
}

.c-text-secondary {
    color: var(--c-text-secondary);
}

table {
    /* table-layout: fixed; */
    width: 100%;
}

th,
tbody,
td {
    border-top: none !important;
    border-bottom: none !important;
}

th.text-left,
td.text-left {
    width: 100% !important;
}

td.merged-column {
    width: 300px !important;
}

th:first-child,
td:first-child {
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
}

th:last-child,
td:last-child {
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: var(--c-bg-correct-answer);
}

.grade-column {
    min-width: 50px !important;
}

.collapse.subjects-row.show,
.subjects-row.collapsing {
    width: 100%;
    display: flex !important;
}

span.progress-default {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

span.progress-success {
    background-color: var(--c-bg-correct-marked);
}

span.progress-danger {
    background-color: var(--c-bg-wrong-marked)
}

span.progress-warning {
    background-color: rgb(255, 206, 86);
}

.progress-circle {
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.progress-circle .progress-inner {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Base Css End */

/* header css start  */

.header-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    -webkit-box-shadow: 0px 1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px 1px 5px rgba(51, 53, 85, 0.1);
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 5;
}

.header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header-wrap .right {
    grid-gap: 1rem;
}

.user-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}


.user-part li {
    position: relative;
}

.user-part li ul.submenu {
    position: absolute;
    background: white;
    border-radius: 10px;
    right: -10px;
    min-width: 300px;
    top: 60px;
    margin: 0;
    padding: 10px 0;
    opacity: 0;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);
    visibility: hidden;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 0;
}

.submenu:before {
    content: "";
    width: 150%;
    height: 40px;
    top: -30px;
    right: 0;
    position: absolute;
}

.user-part li:hover ul.submenu {
    opacity: 1;
    visibility: visible;
}

.submenu-item {
    padding: 10px 15px;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333555;
}

.submenu-item:hover {
    background-color: #ffaa40;
    color: white;
}

.user-img {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user-img span {
    width: 45px;
    height: 45px;
    font-size: 1.9rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #28c76f;
    font-weight: 800;
    background-color: rgba(40, 199, 111, 0.12);
    padding: unset !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 50%;
}

.user-img h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin-bottom: 3px;
}

.user-img p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin-bottom: 35px;
}

.user-part li .submenu.notification li {
    margin: 0;
}

.user-part li .submenu.notification li a {
    padding: 10px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
}

.user-part li .submenu.notification li a span {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
    display: block;
}

.user-part li .submenu.notification li:not(:last-child) {
    border-bottom: 1px solid #888999;
}

.user-part li .submenu.notification li a.white {
    color: #fff;
}

.user-part .box-btn {
    width: 180px;
}

.logo img {
    width: auto;
    height: 40px;
}

.progressBarStyleRed {
    background: #d20404;
}

.progress-exam {
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 5px;
    /* display: block; */
    height: 6px;
    max-width: 124px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    margin-top: 12px;
}

.lang {
    position: relative;
    padding: 0.75rem;
    border-radius: 0.5rem;
}

.lang:hover {
    background: var(--c-bg-primary);
    cursor: pointer;
    color: white;
}

.lang:hover>.lang_menu {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.lang_menu {
    position: absolute;
    top: 68px;
    right: -60px;
    background-color: #fff;
    margin: 0;
    padding: 10px 0;
    min-width: 150px;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.lang_menu:before {
    content: "";
    width: 75%;
    height: 40px;
    top: -30px;
    left: 0;
    position: absolute;
}

.lang_menu li {
    padding: 10px 15px;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333555;
}

.lang_menu li:hover,
.lang_menu li.active {
    background-color: #ffaa40;
    color: white;
}

.lang_menu li.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.lang_menu li span.lang_shortcode {
    margin-right: 0.5em;
    font-weight: 600;
}
/* header css end */

/* sidebar css start  */
@media print {
    .sidebar {
        display: none;
    }
}

.sidebar {
    position: fixed;
    top: 60px;
    width: 80px;
    background: #ffffff;
    left: 0;
    height: 100%;
    z-index: 4;
}

.sidebar-menu li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 42px;
    height: 42px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}

.hover-text {
    white-space: nowrap;
    position: absolute;
    left: -120%;
    border: 1px solid #dddee8;
    border-radius: 0 10px 10px 0;
    height: 42px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 15px;
    z-index: -1;
    letter-spacing: -0.005em;
    color: #333555;
    font-weight: 700;
    font-size: 14px;
    padding-left: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    background: #fff;
}

.sidebar-menu li a {
    background: #ffffff;
    border: 1px solid #dddee8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    position: relative;
}

.sidebar-menu li a:hover .hover-text {
    left: 100%;
    opacity: 1;
    visibility: visible;
    border: 1px solid var(--c-bg-primary);
}

.sidebar-menu li.active a {
    background: var(--c-bg-primary-hover);
    border: 1px solid var(--c-bg-primary);
}

.sidebar-menu li a:hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    border: 1px solid var(--c-bg-primary);
    border-right-color: transparent;
}

.sidebar-menu li a span.menu-icon svg path {
    fill: #888999;
}

.sidebar-menu li.active a span.menu-icon svg path {
    fill: #333555;
}

.sidebar-menu li.active a:hover {
    background: #fff;
}

.sidebar-menu li a span.menu-icon {
    margin-bottom: 3px;
}

.sidebar-menu li a:hover span.menu-icon svg path {
    fill: var(--c-bg-primary);
}

.sidebar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar-menu li {
    margin-bottom: 20px;
}

.sidebar-menu li a::before {
    position: absolute;
    height: calc(100% - 2px);
    background: #fff;
    content: "";
    left: -21px;
    top: 0;
    width: 20px;
}

/* sidebar css end */

/* main css start  */

.content-block {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px;
}

.main-content-wrapper {
    height: 100%;
    margin-left: 80px;
    margin-top: 60px;
}

.main-content-report-wrapper {
    height: 100%;
    margin-top: 60px;
}

.main-content-wrapper .row.main {
    min-height: 100%;
}

.result-page .main-content-wrapper {
    padding: 20px;
}

.right-sidebar-wrap {
    background: #fff;
    height: 100%;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.main-left-wrapper {
    padding: 55px 60px;
}

.tertiary-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.005em;
    color: #888999;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.exam-wrp {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.exam-wrp p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.005em;
    color: #333555;
    margin-bottom: 20px;
}

.exam-code {
    display: grid;
    grid-template-columns: auto 100px;
    grid-gap: 20px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.exam-code-left input {
    background: #ffffff;
    border: 1px solid #dddee8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    height: 44px;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    font-weight: bold;
}

.exam-code-left input:focus,
.exam-code-left input:active {
    border: 2px solid var(--c-bg-primary) !important;
}

.exam-code-left label {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
    text-align: left;
    display: block;
}

.box-btn {
    background: #ffaa40;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    border: 0;
    padding: 10px 15px;
    transition: all 0.3s;
    max-width: 200px;
}

.box-btn.btn-sm {
    font-size: 1.3rem;
    line-height: 22px;
    padding: 4px 10px;
}

.style-3 {
    font-size: 14px;
    padding: 3px 11px;
}

.box-btn:hover {
    filter: brightness(0.9);
    color: #ffffff;
}

.exam-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #333555;
}

.active-exam-wrap p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
}

.active-exam-wrap p img {
    width: 15px;
    margin-right: 4px;
}

.active-exam-wrap p span {
    margin-right: 20px;
}

.exam-table span.badge {
    font-size: 85%;
    line-height: 0.8;
    background-color: var(--c-bg-correct-marked);
    color: white;
}

button.start {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    width: 95px;
    padding: 0;
    height: 36px;
    padding-bottom: 2px;
}

.exam-table tr td:last-child {
    text-align: right;
}

.exam-table>div {
    border-bottom: 1px solid #dddee8;
    padding-bottom: 1rem;
}

.exam-table>div:not(:last-child) {
    margin-bottom: 3rem;
}

.result h4 {
    font-size: clamp(2rem, 2vw, 2.5rem) !important;
}

.result button {
    font-size: clamp(1.3rem, 3vw, 1.8rem);
}

.exam-table td {
    border-top: 0;
    vertical-align: middle !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-block-wrap:not(:last-child) {
    margin-bottom: 35px;
}

.guide-content button,
.guide-content a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.005em;
    color: #333555;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.guide-content button:focus,
.guide-content a:focus {
    box-shadow: none;
}

.guide-content button:hover p,
.guide-content a p {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.guide-content button:hover p,
.guide-content a:hover p,
.guide-content a:hover {
    color: var(--c-bg-primary);
}

.guide-content p:not(.exam-name, .nth-session) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
}

.single-guide {
    display: flex;
    justify-content: space-between;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
}

.left-shadow,
.main.single-guide {
    box-shadow: -6px 0px 0px 0px var(--c-bg-primary);
    border-radius: 10px;
}

/*.user-guide-wrap .accordion .collapse.show*/
.results .accordion .collapse,
.results .accordion .collapsing {
    margin-left: 3rem;
}

.main.single-guide button {
    white-space: nowrap;
}

.guide-img {
    position: relative;
}

.guide-img img {
    max-width: 100%;
    width: 100%;
}

.accordion .single-guide.main a.btn.text-left {
    position: relative;
}

.accordion.table-row .accordion-arrow,
.result .accordion .accordion-arrow,
.accordion .single-guide .accordion-arrow {
    white-space: nowrap;
}

.accordion.table-row .accordion-arrow:after,
.result .accordion .accordion-arrow:after,
.accordion .single-guide .accordion-arrow:after {
    content: "\f106";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    right: 5px;
    font-size: 1.5rem;
    margin-left: 5px;
    line-height: 1;
}

.accordion.table-row.collapsed .accordion-arrow:after,
.result .accordion .collapsed .accordion-arrow:after,
.accordion .single-guide.collapsed .accordion-arrow:after {
    content: "\f107";
}

.accordion.table-row .visibility-hidden.accordion-arrow:after {
    content: "\00a0\00a0\00a0";
}

.single-guide p.exam-lesson {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.005em;
}

.box-btn.style-2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    background: #21cf9a;
    transition: 0.3s;
}

.bdr-bottom {
    border-bottom: 1px solid #dddee8;
}

.bdr-top {
    border-top: 1px solid #dddee8;
}

.single-guide {
    padding: 10px;
    border-radius: 0px 10px 10px 0px;
}

.single-guide:not(.non-accordion) {
    cursor: pointer;
}

.single-guide.in-sidebar .guide-img img {
    border-radius: 5px;
}

.single-guide.in-sidebar .guide-content a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: -0.005em;
    color: #333555;
    transition: 0.3s;
}

.single-guide.in-sidebar .guide-content a:hover {
    color: #21cf9a;
}

.single-guide.in-sidebar {
    grid-template-columns: 65px auto;
}

.right-sidebar-block .tertiary-title {
    margin-bottom: 30px;
}

.footer-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    padding: 24px 0;
    background-color: #eef1f4;
}

.footer-text a {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    text-decoration: underline;
    margin-left: 10px;
    display: inline-block;
}

.msg-popup-content h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
    margin-bottom: 15px;
}

.msg-popup-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #333555;
    margin-bottom: 20px;
}

.exam-code-left textarea {
    background: #ffffff;
    border: 1px solid #dddee8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 120px;
    padding: 15px;
    font-size: 16px;
}

/* calendar */

.react-calendar {
    font-family: unset;
    line-height: unset;
    border: unset;
    width: 100%;
}

.react-calendar__navigation {
    background-color: #ffaa40;
    height: unset;
    margin-bottom: unset;
}

.react-calendar__navigation button {
    color: #ffffff;
    font-size: 27px;
    min-width: unset;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__navigation button:hover,
.react-calendar__navigation button:focus,
.react-calendar__navigation button:active {
    color: #000000;
}

.react-calendar__month-view__weekdays {
    background-color: #ddd;
    padding: 4px 0px;
    font-size: 0.85em;
}

.react-calendar__tile.exam-day abbr {
    position: relative;
}

.react-calendar__tile.exam-day abbr::after {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    background: black;
    bottom: -5px;
    height: 3px;
    content: "";
    width: 3px;
    left: 50%;
}

.exam-day abbr {
    font-weight: 700;
}

.exam-hint {
    display: none;
}

.exam-day:hover .exam-hint {
    display: block;
    position: absolute;
    z-index: 1;
    width: fit-content;
    height: fit-content;
    max-width: 200px;
    max-height: 100px;
    overflow-y: auto;
    background-color: var(--c-bg-primary);
    padding: 15px;
    text-align: start;
    list-style: decimal;
    color: white;
    transform: translate(-45px, 10px);
}

.exam-hint li {
    margin-left: 15px;
    font-size: 1.2rem;
    font-weight: 600;
}

.react-calendar__tile--active:not(.react-calendar__tile--now) {
    background: unset !important;
    border: 1px solid #ffaa40 !important;
    outline-offset: 1px;
    color: inherit !important;
}

.react-calendar__tile {
    border: 1px solid transparent !important;
}

.react-calendar__tile--now {
    background: #ffaa40 !important;
    color: white;
}

.react-calendar__tile:enabled:hover,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--c-bg-primary-hover);
}

.react-calendar__month-view__days {
    background: #fafafa;
}

.react-calendar__tile--hasActive {
    background: #ffaa40 !important;
}

.react-calendar__year-view,
.react-calendar__decade-view,
.react-calendar__century-view {
    margin-top: 3px;
}

/* CSS calender end */

/* Add media queries for smaller screens */

/*
@media screen and (max-width:720px) {
  .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {width: 12.5%;}
  .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {width: 12.2%;}
}
*/

/* CSS calsender end */

/* home page css start  */

.more {
    text-decoration-line: underline;
    color: #888999;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    transition: 0.3s;
}

.more:hover {
    color: #21cf9a;
    text-decoration-line: underline;
}

.single-s-card {
    background: #e3e4ff;
    border-radius: 10px;
    padding: 30px;
}

.single-s-card .s-img img {
    max-width: 50px;
}

.single-s-card .s-img {
    min-height: 50px;
    margin-bottom: 21px;
}

.single-s-card h4 {
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 24px;
}

.light-indigo-bg {
    background: #beeeff;
}

.light-red-bg {
    background: #ffdbdf;
}

.box-btn.yellow-bg {
    background: #ffaa40;
    font-size: 18px;
}

.user-guide-wrap.home-pge p {
    color: #333555;
    font-size: 16px;
}

.primary-title h2 {
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 24px;
}

.primary-title {
    margin-bottom: 24px;
}

/* home page css end */

/* profile page css start  */

.profile-photo-blk {
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    position: relative;
    height: 150px;
    padding: 15px;
}

.profile-photo {
    position: relative;
}

.profile-photo .change-photo {
    position: absolute;
    right: -4px;
    bottom: 28px;
    z-index: 2;
}

.change-photo {
    background: #21cf9a;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.change-photo input {
    position: absolute;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}

.edit-photo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-photo img {
    width: 15px;
}

.profile-photo {
    margin-top: -50px;
    max-width: 108px;
    margin-left: auto;
    margin-right: auto;
    max-height: 108px;
    margin-bottom: 12px;
}

.profile-photo-blk h4 {
    color: #333555;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.profile-content-blk {
    background: #fff;
    padding: 50px 15px;
    padding-bottom: 20px;
}

#v-pills-tab a {
    text-align: right;
    letter-spacing: -0.005em;
    color: #333555;
    padding: 8px 20px;
}

#v-pills-tab a.active {
    background: transparent;
    font-weight: 700;
}

.input-inside input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 10px;
    height: 32px;
    padding: 3px 15px;
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 16px;
    font-weight: 700;
}

.input-inside input::-moz-placeholder,
.input-inside input::-webkit-placeholder {
    opacity: 1;
    color: #333555;
}

.input-inside label {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
    padding-left: 34px;
    text-align: left;
}

.input-inside {
    margin-bottom: 12px;
}

#v-pills-tab {
    height: 100%;
    border-right: 1px solid #dddee8;
}

.pf-tab-content-wrap {
    padding-left: 30px;
    max-width: 550px;
}

.form-error.error {
    display: block;
    color: red;
    font-size: 12px;
    padding-left: 13px;
}

.login-error {
    font-size: 12px;
    letter-spacing: 0.01em;
}

.main-left-wrapper.flx-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 0;
}

.main-left-wrapper.flx-wrap .footer-text {
    margin-top: auto;
}

.change-photo.for-cover-photo {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 2;
}

/* profile page css end */

/* exam page css start  */

/* timer css start  */

.settings-area {
    margin-right: 10px;
    padding-right: 5px;
    border-right: 1px solid #dddee8;
}

.settings-area>.dropdown .btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.settings-area .dropdown-menu {
    min-width: 15rem;
}

.settings-area>.dropdown .dropdown-item span {
    width: 14px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
}

.settings-area .dropdown-toggle::after {
    display: none;
}

.countdown {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 135px;
    max-width: 20rem;
    margin: 0 auto;
}

.countdown_red {
    color: #d20404 !important;
}

.countdown__item {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    min-width: 31%;
    margin-bottom: 1rem;
}

.countdown__item--large {
    -webkit-box-flex: 1;
    flex: auto;
    width: 100%;
    font-size: 2.75em;
}

.countdown__timer {
    background-color: transparent;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: #333555;
    text-align: center;
}

.countdown__label {
    padding-top: 0;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
}

.countdown__item--large .countdown__label:before,
.countdown__item--large .countdown__label:after {
    content: "";
    display: block;
    height: 1px;
    background-image: -webkit-gradient(linear,
            left top,
            right top,
            from(transparent),
            color-stop(rgba(0, 0, 0, 0.4)),
            to(transparent));
    background-image: linear-gradient(left, transparent, rgba(0, 0, 0, 0.4), transparent);
}

/* timer css end */

.exam-header-right {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.empty-answers-modal .fa-lg,
.empty-answers-modal .modal-title {
    font-size: 2.33em;
}

.box-btn.style-2.in-header {
    background: #333555;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    height: 42px;
}

.box-btn.style-2.in-header:hover {
    background: #dc3545;
}

.box-btn.style-2.in-header img {
    margin-right: 10px;
}

.timer-progress-wrap span {
    background: linear-gradient(90deg, #888999 12.57%, rgba(196, 196, 196, 0) 12.72%);
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    height: 6px;
    max-width: 124px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    margin-top: 10px;
}

.exam-timer-wrap {
    position: relative;
    padding-left: 18px;
}

.exam-timer-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.exam-header-left {
    display: flex;
    align-items: center;
    display: flex;
    flex: 1;
}

.exam-header-left .logo {
    margin-right: 15px;
    flex: 0 0 60px;
}

.exam-header-left .input-inside {
    position: relative;
    margin-bottom: 0;
}

.exam-header-left .input-inside .sc-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    right: 15px;
}

.exam-header-left .input-inside .sc-icon-small {
    color: #888999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline;
    left: 0px;
    /* right: 53px; */
}

.input-inside .exam-name {
    margin-left: 42px;
}

.small-screen-exam-title {
    display: none;
    margin-bottom: 10px;
}

.exam-name h2 {
    font-size: 1.1em;
    margin-bottom: 0;
    letter-spacing: -0.005em;
    cursor: pointer;
}

.exam-name.has-description h2:after {
    font-family: "Font Awesome 5 Free";
    color: #888999;
    position: relative;
    left: 5px;
    content: "\f05a";
    font-weight: 900;
    font-size: 12px;
}

.notification-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 420px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification-info {
    background-color: #454652;
}

.notification-info .title {
    font-size: 1.5em;
    color: #fff;
    line-height: 1.2em;
    font-weight: 800;
    margin: 0 0 5px;
}

.notification-info div.text-small p {
    font-size: 1.2rem;
    color: white;
}

#accordion label:after {
    content: url(http://image.noelshack.com/fichiers/2016/48/1480523700-arrow-dl.png);
    position: absolute;
    right: 0;
}

/* .exam-box {
	display: flex;
	flex-direction: row;
} */

.exam-question-wrap {
    background: #fff;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 0;
    /* height: calc(100vh - 144px); */
    width: 100%;
    overflow: hidden;
}

.result-page .exam-question-wrap {
    margin-right: 20px;
}

/* RESULT PAGE */

table thead th {
    font-size: 2rem;
}

table tbody th {
    font-size: 1.5rem;
}

table td {
    font-size: 1.4rem;
}

.exam-question-wrapper {
    display: flex;
    /* display: -webkit-box; */
    /* align-items: flex-start; */
}

.main-content-wrapper.exam-answers,
.main-content-wrapper.exam-pge {
    padding: 20px;
}

.main-content-wrapper.exam-pge {
    margin-left: 0;
    margin-right: 0;
}

.main-content-wrapper.exam-answers {
    margin-left: 80px;
}

.mcq-header {
    border-bottom: 1px solid #dddee8;
}

.mcq-header .filter-arrow {
    display: block;
    width: 16px;
    cursor: pointer;
    text-align: center;
}

.container.result .btn-outline-primary {
    line-height: 23.4px;
}

.container .timed i,
.container.result i {
    color: red;
}

.result-page .mcq-header {
    height: 42px;
    justify-content: center;
}

.mcq-header p {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--c-bg-answer);
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--c-bg-primary);
    border-radius: 12px;
    padding: 5px;
    min-width: 68px;
}

.mcq-header p span {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    display: block;
}

.filter-block {
    padding: 4px 6px 4px 6px !important;
}

.left-bar-filter {
    cursor: pointer;
    transition: all 0.3s;
}

.left-bar-filter:hover {
    background: var(--c-bg-primary-hover);
    filter: brightness(0.9);
}

.left-bar-filter.active {
    cursor: pointer;
    background: var(--c-bg-primary);
    color: white;
}

.left-bar-filter.active>span {
    color: white;
}

.filter-null-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 2rem;
    text-align: center;
    color: var(--c-bg-answer);
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.filter-result-null-text {
    display: block;
    height: calc(100vh - 286px);
    text-align: center;
    padding: 20px;
    color: #888999;
}

.mcq-wrap {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    flex-basis: 265px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 92px;
}

.mcq-content-wrap {
    /* max-height: calc(100vh - 235px);
  overflow-y: auto; */
}

.mcq-option {
    background: #ffffff;
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 12px;
    width: 22px;
    height: 22px;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
}

.mcq-option.answer-selected {
    background: var(--c-bg-answer);
    color: #ffffff;
}

.mcq-option.answer-filled {
    filter: invert(56%) sepia(13%) saturate(340%) hue-rotate(199deg) brightness(100%) contrast(83%);
}

.mcq-option.wrong {
    background-color: var(--c-bg-wrong-marked);
    color: white;
}

.mcq-option.correct {
    background-color: var(--c-bg-correct-marked);
    color: white;
}

.result-icon {
    width: 14px;
}

.audio-icon {
    padding: 0px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.audio-icon img {
    width: 32px;
    padding: 5px;
    filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(123deg) brightness(101%) contrast(102%);
}

.stopwatch {
    display: flex;
    justify-content: center;
}

.stopwatch span {
    font-size: 3rem;
    color: #737378;
}

.audio-player {
    height: 40px;
}

.sl-no {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.05em;
    color: #888999;
    margin-right: 2px;
    min-width: 25px;
}

.mcq-content-wrap {
    /* padding-left: 12px;
    padding-right: 5px;
    padding-top: 14px;
    padding-bottom: 10px; */
}

.single-mcq-row {
    margin-top: 3px;
    margin-bottom: 3px;
    background: transparent;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    width: 200px;
    white-space: nowrap;
    display: flex;
    margin-right: auto;
    margin-left: auto;
}

.trans-badge {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
}

.single-mcq-row.active,
.single-mcq-row:hover {
    background: #f3f4fa;
}

.single-mcq-row.active .sl-no,
.single-mcq-row:hover .sl-no {
    color: #21cf9a;
}

.all-question-btns button.box-btn {
    background: #ffffff;
    border: 1px solid var(--c-bg-primary);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: bold;
    font-size: 10px;
    line-height: 8px;
    /* line-height: 1.5em; */
    height: 3.7em;
    text-align: center;
    letter-spacing: 0.02em;
    color: #333555;
    /* padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px; */
    padding: 0 12px;
    margin-bottom: 2px;
    margin-top: 2px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.all-question-btns button.box-btn:hover {
    background-color: var(--c-bg-primary-hover);
}

.all-question-btns button.box-btn span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 8px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
}

.question-header {
    border-bottom: 1px solid #dddee8;
    padding: 5px;
    /* white-space: nowrap; */
    /* overflow: overlay; */
}

.all-question-btns button:not(:last-child) {
    margin-right: 5px;
}

.all-question-btns button.box-btn.active {
    background: var(--c-bg-primary);
    border-color: var(--c-bg-primary);
    color: #fff;
}

.all-question-btns button.box-btn.active span {
    color: #fff;
}

.mcq-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #dddee8;
    padding: 7px;
}

.mcq-footer label {
    display: block;
    margin-bottom: 0px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    text-align: center;
}

.mcq-footer .dropdown-menu {
    min-width: min-content;
}

.mcq-footer .dropdown-item {
    font-size: 12px;
    cursor: pointer;
}

.mcq-footer .dropdown-item:hover,
.mcq-footer .dropdown-item:active {
    background-color: var(--c-bg-primary);
    color: white;
}

.exam-status-wrap {
    width: 50px;
}

.all-type-btn {
    background: #ffffff;
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 20px;
}

.all-type-btn button img {
    max-width: 100%;
}

.all-type-btn button:not(:last-child) {
    border-bottom: 1px solid #dddee8;
}

.all-type-btn button {
    padding: 12px;
    background: #fff;
    border: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    letter-spacing: -0.005em;
    color: #888999;
}

.all-type-btn button:first-child {
    border-radius: 10px 10px 0 0;
}

.all-type-btn button:last-child {
    border-radius: 0 0 10px 10px;
}

.all-type-btn.gradee button.text-black {
    color: #333555;
}

.all-type-btn button.active {
    background: #888999;
}

.question-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    height: calc(100vh - 220px);
}

.answer-optons label {
    background: #ffffff;
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #333555;
    /* height: 40px; */
    display: flex;
    align-items: center;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
}

.answer-optons label .mcq-option {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.005em;
    color: #333555;
    margin-right: 10px;
    flex-shrink: 0;
}

.answer-optons label .mcq-option.multiple-answer {
    border-radius: 5px;
}

.answer-optons label input {
    position: absolute;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    visibility: hidden;
}

.result-page .answer-optons label {
    cursor: not-allowed;
}

.answer-optons label p {
    line-height: 1.2em;
    font-size: 1.5rem;
}

.answer-optons label.correct p,
.answer-optons label.correct div {
    color: var(--c-bg-correct-marked);
    font-weight: 700;
}

.answer-optons label.wrong p,
.answer-optons label.wrong div {
    color: var(--c-bg-wrong-marked);
    font-weight: 700;
}

.question-text.parent {
    margin-left: 58px;
}

.question-text.parent .info {
    max-width: max-content;
}

.question-text p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 25px;
    /* max-width: 99%; */
}

.question-text p span {
    font-size: unset;
    /* line-height: 1.5; */
}

.ql-formula {
    font-size: 1.5em !important;
}

.question-text h4 {
    font-size: 16px;
    margin-bottom: 26px;
}

.question-sl {
    flex: 0 0 40px;
    text-align: center;
    padding-right: 28px;
}

.question-sl .question-number {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    width: 30px;
    letter-spacing: -0.075em;
    cursor: pointer;
}

.result-page .question-sl img {
    cursor: not-allowed !important;
}

.question-text-wrap {
    padding-right: 20px;
}

.question-text.parent i,
.question-text-wrap i {
    line-height: 24px;
    color: var(--c-bg-primary);
}

.twin-btn-wrap {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    background: #fff;
    border-top: 1px solid #dddee8;
    -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    z-index: 4;
    margin: 0 auto;
    max-width: fit-content;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.twin-btn-wrap a {
    padding: 3px 14px !important;
}

a:not([href]):not([tabindex]) {
    color: #888999;
    text-decoration: none;
    font-size: 14px;
}

.twin-btn-wrap a:first-child {
    border: 1px solid #888999;
    background: transparent;
    /* color: #888999; */
    margin-right: 10px;
}

.twin-btn-wrap a:first-child:hover {
    background: #888999;
    color: #fff;
}

.twin-btn-wrap a:nth-child(2) {
    background: #888999;
    border: 1px solid #888999;
}

.twin-btn-wrap a:nth-child(2):hover {
    background: #21cf9a;
    border: 1px solid #21cf9a;
}

.exam-header-left .input-inside input {
    color: #888999;
    font-weight: normal;
}
/* exam page css end */

/* exam-more-question page css start  */
.all-type-btn button.active svg rect {
    fill: #fff;
}

.all-type-btn button.active svg path {
    fill: #fff;
}

.all-type-btn button svg {
    max-width: 100%;
    max-height: 30px;
}

.box-btn.style-2.gray-bg.red-bg {
    background: #dc3545;
    border-color: #dc3545;
}

.c-modal-inner-wrap.max-500 {
    max-width: 500px;
}

.box-btn.style-2.gray-bg.red-bg:hover {
    background: transparent;
    color: #dc3545;
}

.exam-question-wrap.style-2 {
    flex: 0 0 calc(100% - 270px);
}

.mcq-wrap.wide {
    flex: 0 0 180px;
}

.mcq-wrap.wide .mcq-option.fill {
    background: #888999;
    color: #fff;
    border-color: #888999;
}

.mcq-wrap.wide .mcq-option {
    font-weight: normal;
    font-size: 12px;
}

.mcq-header.style-2 p {
    background: #f3f4fa;
    border: 1px solid #dddee8;
    box-sizing: border-box;
    border-radius: 10px;
    width: 33.33%;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 10px;
}

.single-question-scroll-wrap .single-question {
    margin-bottom: 50px;
    padding-top: 30px;
}

.mcq-header.style-2 p.active {
    color: #21cf9a;
}

.answer-optons label.active {
    background: #dddee8;
    border: 1px solid #888999;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(51, 53, 85, 0.15);
}

.answer-optons label.active .mcq-option {
    background: #333555;
    border: 1px solid #dddee8;
    color: #fff;
}

.answer-optons label.correct {
    background: var(--c-bg-correct-answer);
    border: 1px solid var(--c-bg-correct-marked);
    box-sizing: border-box;
}

.answer-optons label.correct .mcq-option {
    background: var(--c-bg-correct-marked);
    border: 1px solid #dddee8;
    color: #fff;
}

.answer-optons label.wrong {
    background: var(--c-bg-wrong-answer);
    border: 1px solid var(--c-bg-wrong-marked);
    box-sizing: border-box;
    color: #fff;
}

.answer-optons label.wrong .mcq-option {
    background: var(--c-bg-wrong-marked);
    border: 1px solid #dddee8;
    color: #fff;
}

.box-btn.style-2.in-header.red-bg {
    background: #dc3545;
}

.mcq-wrap.wide .mcq-option {
    margin-bottom: 6px;
}

.timer-progress-wrap.activee span {
    background: linear-gradient(90deg, #ffaa40 64.82%, rgba(196, 196, 196, 0) 64.99%);
}

/* exam-more-question page css end */

/* exam-end page css start  */

.exam-over-summary p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 15px;
}

.exam-over-summary p a {
    color: #000;
    text-decoration: underline;
}

.box-btn.style-2.in-header.gray-bg {
    background: #888999;
}

.exam-over-summary {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px;
}

.main-content-wrapper.exam-pge.exam-end-pge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(90vh - 80px);
}

.twin-btn-wrap {
    text-align: center;
    margin-top: auto;
}

.twin-btn-wrap a {
    color: #888999;
    margin: 0 5px;
    position: relative;
}

.twin-btn-wrap a.box-btn.style-2 {
    line-height: auto !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.twin-btn-wrap-disabled {
    cursor: not-allowed;
    background: #dddee8 !important;
    border: 1px solid #dddee8 !important;
    color: #ffffff !important;
}

.twin-btn-wrap-disabled:hover {
    cursor: not-allowed;
    background: #dddee8 !important;
    border: 1px solid #dddee8 !important;
}

.twin-btn-wrap-enabled {
    cursor: pointer;
    border: 1px solid #888999;
    background: transparent !important;
}

.twin-btn-wrap-enabled:hover {
    border: 1px solid var(--c-bg-primary) !important;
    background: var(--c-bg-primary) !important;
    color: #fff !important;
}

.question-answer p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
}

.question-answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
}

.answer-textarea {
    height: 100px;
    width: 300px;
}

/* exam-end page css end */

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.question-course {
    display: block;
    color: #878899;
    font-size: 9px;
    font-weight: 400;
    margin-left: -1px;
    left: 14px;
    top: 0px;
    position: absolute;
    border: 1px solid #dddee8;
    padding: 1px 6px;
    border-radius: 5px 5px 5px 0;
    background: rgba(255, 255, 255, 0.85);
    letter-spacing: normal;
    white-space: nowrap;
}

.question-course:before {
    content: "";
    display: block;
    border: 1px solid #dddee8;
    width: 7px;
    height: 7px;
    position: absolute;
    left: -1px;
    bottom: -7px;
    border-width: 7px 7px 0px 1px;
    border-right-color: transparent;
}

.question-course:after {
    content: "";
    display: block;
    border: 1px solid #fff;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0px;
    bottom: -5px;
    border-width: 6px 6px 0px 1px;
    border-right-color: transparent;
}

.exam-bottom {
    width: auto;
    text-align: left;
}

.questions-summary {
    line-height: 1.2;
    padding: 10px 0 !important;
    padding: 15px 0;
}

.summary-block {
    display: inline-block;
}

.questions-summary span {
    padding: 0 5px 0 0;
    font-size: 12px;
}

.questions-summary-number {
    font-weight: bold;
}

.course-select {
    display: none;
}

.back-btn {
    position: absolute;
    top: 2px;
    font-size: 11px;
    cursor: pointer;
    color: #21cf9a;
}

.back-btn:hover {
    color: #ffaa40;
}

.back-btn:focus,
.back-btn:active {
    outline: none;
    box-shadow: none;
}

.simple-bar {
    padding: 10px 20px 10px 30px;
}

.simple-bar-left {
    height: calc(100vh - 270px);
    padding: 10px;
}

.result-page .simple-bar-left {
    height: calc(100vh - 266px);
}

.result-page .question-content {
    height: calc(100vh - 212px);
}

.result-page .exam-question-wrap {
    height: calc(100vh - 170px);
}

.simplebar-scrollbar::before {
    background-color: #888999;
}

.simplebar-content-wrapper:focus-visible {
    outline: none;
}

.post-img {
    max-width: 100%;
    object-fit: cover;
    padding-bottom: 25px;
}

.auth_container {
    box-shadow: 0 4px 25px rgb(0 0 0 / 10%);
}

.login-page .navbar {
    padding: 0.5rem 11vw !important;
}

.logo_wrapper img.logo {
    max-width: 100%;
}

.btn-letter-spacing {
    letter-spacing: 0.2em;
}

.auth_footer {
    font-weight: 500;
    font-size: 12px;
    color: rgba(98, 98, 98, 0.6);
}

.modal-backdrop {
    z-index: 4 !important;
}

.rounded {
    border-radius: 1.25rem !important;
}

.modal-content {
    padding: 5px;
    border-radius: 1.3rem !important;
}

.modal-header {
    border: none !important;
}

.modal-footer {
    border: none !important;
}

.modal-close-icon {
    top: -11px;
    right: -8px;
}

.exam-guide .exam-informations div span {
    font-size: clamp(1.75rem, 3vw, 2.5rem);
    font-weight: 600;
}

.exam-guide svg {
    scale: 0.9;
}

.tooltip-inner,
.tooltip-inner span {
    font-size: 14px;
}

.tooltip-icon {
    background-color: #e5e5e5;
    color: black;
    margin: 3px 0px 8px 0px;
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
    -webkit-box-shadow: 0px 4px 0px 0px rgba(209, 213, 219, 1);
    -moz-box-shadow: 0px 4px 0px 0px rgba(209, 213, 219, 1);
    box-shadow: 0px 4px 0px 0px rgba(209, 213, 219, 1), 0px 5px 0px 0px rgb(153, 154, 156);
}

.tooltip-inner .tooltip-icon:not(:last-child) {
    margin-right: 4px;
}

.result-table li.table-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.result-table .table-header {
    background-color: var(--c-body);
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.result-table .table-row {
    background-color: #fff;
    font-size: clamp(1rem, 2.5vw, 1.4rem);
}

.result-table li div.col:not(:first-child) {
    display: flex;
    justify-content: center;
}

.table-header>div>span {
    font-weight: 600;
    font-size: clamp(1rem, 2.5vw, 1.6rem);
}

.result .card-header {
    padding: 1.5rem;
    margin-bottom: 0;
    color: var(--c-text-dark-secondary);
    background-color: var(--c-white);
    border-bottom: 1px solid var(--c-card-border-color);
}

.order-column {
    text-align: center;
    color: #888999;
    font-weight: 600;
}

/* add margin bottom to all children except the last child */
.classroom-results > div:not(:last-child) {
    margin-bottom: 3rem;
}


ul.chart {
	display: grid;
	gap: 0 2em;
	align-items: end;
	margin: 0;
	padding: 0;
	list-style-type: none;
	grid-auto-rows: 1fr;
	grid-auto-columns: 1fr;
    height: 100%;
	min-height: 12rem;
    max-width: 400px;
}

ul.chart > li {
	background: #ffd100;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column-reverse;
	padding: 0.5rem 0.25rem;
	height: 100%;
    font-size: 15px;
}

ul.chart li span {
	font-weight: bold;
}

.only-print {
    display: none;
}

@media print {
    @page {
        size: A4;
        padding: 0 0 20mm 0 !important;
    }

    ul.chart, .bg-light-purple, .bg-light-orange, .bg-light-green, .bg-light-blue, .bg-light-red, .bg-light-yellow, .bg-light-pink, .bg-light-gray, .bg-light-black {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    body {
        width: 1100px !important;
        height: 1275px !important;
        margin: 0 auto;
    }

    .only-print {
        display: block !important;
    }

    .print-page {
        width: 1100px !important;
        height: 1275px !important;
        margin: 0 auto;
        page-break-after: always;
        break-after: page;
        overflow: hidden;
        position: relative;
    }

    /* Add borders to table when printing */
    table, table td, table th, table tr {
        border: 1px solid #000;
    }

    table td, table th, table tr {
        padding: 10px !important;
    }

    .print-break {
        page-break-after: always;
    }

    tr.text-light td {
        color: #000 !important;
        font-weight: 600 !important;
    }

    .col-print-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
  
    .col-print-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  
    .col-print-3 {
      flex: 0 0 25%;
      max-width: 25%; }
  
    .col-print-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  
    .col-print-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
  
    .col-print-6 {
      flex: 0 0 50%;
      max-width: 50%; }
  
    .col-print-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
  
    .col-print-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
  
    .col-print-9 {
      flex: 0 0 75%;
      max-width: 75%; }
  
    .col-print-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
  
    .col-print-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
  
    .col-print-12 {
      flex: 0 0 100%;
      max-width: 100%; 
    }
}
