:root {
    --c-body: #f3f4fa;
    --c-bg-primary: #ffaa40;
    --c-bg-primary-hover: #ffba6799;
    --c-bg-answer: #333555;
    --c-bg-correct-answer: #ddf6e8;
    --c-bg-correct-marked: rgba(75, 192, 192, 1);
    --c-bg-wrong-answer: #fce4e4;
    --c-bg-wrong-marked: rgba(255, 99, 132, 1);
    --c-bg-light-purple: #dddefc;
    --c-bg-dark-purple: "#7367f0";
    --c-bg-light-purple-1: rgb(241, 241, 252);
    --c-bg-light-blue: #d9f8fc;
    --c-bg-dark-blue: #00cfe8;
    --c-bg-light-blue-1: #91c4d7;
    --c-bg-light-orange: #fff1e3;
    --c-bg-dark-orange: #ff9f43;
    --c-text-secondary: #8e909c;
    --c-text-dark-secondary: #5d596c;
    --c-white: "#fff";
    --c-card-border-color: "#dbdade";
}
