/* Normal desktop :1200px. */

/* Normal desktop :992px. */
@media (min-width: 992px) {
    .main-left-wrapper {
        padding: 30px;
    }

    .rightbar {
        position: absolute;
        height: 100%;
        right: 0;
    }

    .single-s-card {
        margin-bottom: 30px;
    }

    .pf-tab-content-wrap {
        padding-left: 0;
    }

    .single-question+div {
        margin-left: 58px;
    }
}

/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991.98px) {
    .toggle-bar {
        display: inline-block;
    }

    .main-left-wrapper {
        padding: 30px;
        padding-bottom: 30px;
    }

    .right-sidebar-wrap {
        background: transparent;
    }

    .single-s-card {
        margin-bottom: 30px;
    }

    .pf-tab-content-wrap {
        padding-left: 0;
        max-width: 100%;
    }

    .single-question+div {
        margin-left: 58px;
    }
}

/* small mobile :320px. */

@media (min-width: 320px) and (max-width: 767.98px) {
    .toggle-bar {
        display: inline-block;
    }

    .main-left-wrapper {
        padding: 20px 15px 30px;
    }

    .right-sidebar-wrap {
        background: transparent;
        padding-bottom: 0;
    }

    .content-block {
        padding: 15px;
    }

    .exam-wrp p {
        line-height: 1.2em;
    }

    .exam-table {
        width: 100%;
    }

    .table-scroll-wrap {
        overflow-x: scroll;
    }

    .single-s-card {
        margin-bottom: 30px;
    }

    .primary-title h2 {
        font-size: 22px;
    }

    .single-s-card h4 {
        font-size: 22px;
    }

    .pf-tab-content-wrap {
        padding-left: 0;
        max-width: 100%;
    }

    #v-pills-tab a {
        text-align: left;
        padding: 5px 0px;
    }

    #v-pills-tab {
        border-right: 0;
    }

    .profile-content-blk {
        padding: 15px 15px;
        padding-bottom: 0;
    }

    .main-left-wrapper.profile-pge {
        padding-top: 55px;
    }

    .result-page .question-content,
    .question-content {
        height: auto;
    }

    .result-page .exam-question-wrap {
        height: auto;
    }

    .all-question-btns button.box-btn {
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .question-header {
        padding-bottom: 3px;
    }

    .exam-box {
        margin-bottom: 50px;
    }

    .exam-question-wrapper {
        /* display: block; */
        flex-direction: column;
        align-items: stretch;
    }

    .mcq-header {
        border-bottom: none;
    }

    .result-page .mcq-header {
        height: 82px;
    }

    .mcq-wrap {
        /* display: flex;
        align-items: center;
        flex-direction: column;
        width: auto;
        overflow: hidden;
        flex: 1;
        order: 2; */

        align-items: center;
        flex-direction: column;
        width: calc(100vw - 20px);
        height: calc(100vh - 130px);
        overflow: hidden;
        padding: 20px;
        order: 2;
        position: fixed;
        background-color: white;
        z-index: 9999999;
        border: 1px solid #dddee8;
    }

    .mcq-content-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mcq-wrap .mcq-option-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 5px;
        height: calc(100vh - 240px);
        overflow: scroll;
        width: 100%;
    }

    /* .mcq-wrap .mcq-option-list:after {
        content: " ";
        display: flex;
        flex: 0 0 10px;
    } */

    .simple-bar {
        min-height: calc(100vh - 318px);
        padding: 10px 20px 10px 20px;
    }

    .mcq-wrap .simple-bar-left {
        height: auto;
        padding: 0;
    }

    .mcq-wrap .simplebar-content-wrapper {
        /* width: 100%; */
    }

    .single-mcq-row {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        width: 150px;
    }

    .main-content-wrapper.exam-pge {
        padding: 10px;
    }

    .mcq-footer {
        border: none;
    }

    .trans-badge {
        position: absolute;
        left: 0;
       
        transform: translateY(-50%);
        width: 9px;
        right: 0;
       
    }

    .single-mcq-row .sl-no {
        text-align: center;
    }

    .all-question-btns button.box-btn {
        padding-left: 10px;
        padding-right: 10px;
    }

    .exam-question-wrap {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 45px;
        max-height: none;
        height: auto;
        order: 3;
    }

    .exam-status-wrap {
        margin-top: 20px;
    }

    .question-text.parent {
        margin-left: 45px;
    }

    .question-sl {
        flex: 0 0 30px;
        text-align: center;
        padding-right: 15px;
    }

    .twin-btn-wrap {
        text-align: right;
        max-width: 100vw;
    }

    .twin-btn-wrap a {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .result-page .twin-btn-wrap {
        text-align: center;
    }

    .exam-bottom {
        left: 10px;
    }

    .questions-summary {
        padding: 5px 0;
    }

    .summary-block {
        display: block;
        width: auto;
    }

    .questions-summary-divider {
        display: none;
    }

    .all-type-btn {
        display: flex;
        flex-direction: column;
    }

    .big-screen-exam-title {
        display: none;
    }

    .small-screen-exam-title {
        display: block;
    }

    .exam-header-left h2 {
        font-size: 1.2em;
    }

    .m-purpose {
        display: none;
    }

    .box-btn.style-2.in-header img {
        margin-right: 0;
    }

    .exam-header-left .logo {
        margin-right: 15px;
        flex: 0 0 40px;
    }

    .mobile-search i {
        font-size: 18px;
        cursor: pointer;
    }

    .c-modal-inner-wrap.max-500 {
        max-width: 88%;
    }

    .single-question-scroll-wrap {
        /* max-height: 667px; */
    }

    .single-question+div {
        margin-left: 45px;
    }

    .question-text h4 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .answer-optons label {
        font-size: 13px;
    }

    .answer-optons label .mcq-option {
        width: 22px;
        height: 22px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333555;
        margin-right: 10px;
    }

    .question-text p {
        font-size: 14px;
        line-height: 21px;
    }

    .sl-no {
        font-size: 14px;
    }

    .countdown__timer {
        font-size: 18px;
        line-height: 18px;
    }

    .mcq-option {
        border-radius: 10px;
        width: 19px;
        height: 19px;
        font-size: 14px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #333555;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .mcq-wrap.wide .mcq-option {
        font-size: 11px;
    }

    .sl-no {
        font-size: 13px;
    }

    .box-btn.style-2 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #ffffff;
        background: #21cf9a;
        padding: 5px;
        transition: 0.3s;
    }

    .box-btn.style-2.in-header {
        border-radius: 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 12px;
    }

    .exam-over-summary {
        order: 1;
        margin-bottom: 10px;
    }

    /* .course-select{
        display: inline;
    } */

    .result-table li {
        padding: 15px;
        margin-bottom: 15px;
    }

    /* sidebar on mobile view */
    .sidebar {
        position: fixed;
        bottom: 0;
        top: auto;
        width: 100%;
        height: 60px;
        z-index: 4;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .sidebar-menu {
        flex-direction: row;
        justify-content: space-around;
        padding: 10px;
        margin-top: 0 !important;
    }

    .sidebar-menu li {
        margin-bottom: 0;
        margin-right: 10px;
    }


    .main-content-wrapper {
        margin-left: 0;
        margin-bottom: 60px;
        /* Height of sidebar */
    }

    .result-page .main-content-wrapper {
        padding: 20px;
        margin-bottom: 60px;
        /* Height of sidebar */
    }

    .sidebar-menu li a:hover .hover-text {
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        border-radius: 10px;
    }

}

/* Large Mobile :480px. */

@media (max-width: 576px) {
    .exam-wrp {
        max-width: unset;
    }

    .exam-code-left {
        width: 100%;
    }

    .exam-code {
        display: flex;
        flex-direction: column;
    }

    .exam-code-left input {
        margin-bottom: 5px;
    }

    .exam-code .box-btn {
        width: 95px;
        padding: 0;
        height: 36px;
    }

    .single-guide {
        display: block;
    }

    .guide-content {
        margin-bottom: 16px;
    }

    .exam-guide svg {
        scale: 0.7;
    }

    .exam-table tr {
        display: flex;
        flex-direction: column;
    }

    .result-table li {
        padding: 5px;
        margin-bottom: 15px;
    }

    .result-table div.col {
        padding-inline: 0px;
    }

    .result-table .table-header div.col span {
        display: none;
    }

    .result-table .table-header div.col:before {
        content: attr(data-label);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {}