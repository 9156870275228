/***FONT***/
/* nunito-sans-300 - latin-ext_latin */
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/nunito-sans-v5-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
    src: local("Nunito Sans Light"), local("NunitoSans-Light"),
        url("../fonts/nunito-sans-v5-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/nunito-sans-v5-latin-ext_latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-300.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-300.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/nunito-sans-v5-latin-ext_latin-300.svg#NunitoSans") format("svg"); /* Legacy iOS */
}
/* nunito-sans-regular - latin-ext_latin */
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/nunito-sans-v5-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Nunito Sans Regular"), local("NunitoSans-Regular"),
        url("../fonts/nunito-sans-v5-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/nunito-sans-v5-latin-ext_latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/nunito-sans-v5-latin-ext_latin-regular.svg#NunitoSans") format("svg"); /* Legacy iOS */
}
/* nunito-sans-700 - latin-ext_latin */
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/nunito-sans-v5-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
    src: local("Nunito Sans Bold"), local("NunitoSans-Bold"),
        url("../fonts/nunito-sans-v5-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/nunito-sans-v5-latin-ext_latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/nunito-sans-v5-latin-ext_latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/nunito-sans-v5-latin-ext_latin-700.svg#NunitoSans") format("svg"); /* Legacy iOS */
}
